import axios from './config';

export const api = {
  request: (url, method, data, v = "v1", asFormData) => {
    return axios({
      url: v + "/" + url,
      method : method == 'get' ? 'get' : 'post',
      data : asFormData ? api.prepareFormData(data, method) : data,
      headers : asFormData ? {'Content-Type': 'multipart/form-data'} : {}
    });
  },
  post: (url, data, asFormData = false, version) => {
    return api.request(url, 'post', data, version, asFormData);
  },
  put: (url, data, asFormData = false, version) => {
    return api.request(url, 'put', data, version, asFormData);
  },
  get: (url, asFormData = false, version) => {
    return api.request(url, 'get',{}, version, asFormData);
  },
  delete: (url, asFormData = false, version) => {
    return api.request(url, 'delete', version, asFormData);
  },
  extractErrorFromResponse: (error) => {
    let errorMessage = "Server error, please try again later.";
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message.toString();
      const errors = error.response.data.errors;
      if (errors) {
        const keys = Object.keys(errors);
        if (errors && keys && keys.length > 0) {
          errorMessage = errors[keys[0]][0].toString();
        }
      }
    }
    return errorMessage;
  },

  prepareFormData : (data, method) => {
    let bodyFormData = new FormData();
    console.log(data, method);
    if(method !== 'get' && method !== 'post'){
      data["_method"] = method;
    }

    Object.keys(data).forEach((value) => {
      if(Array.isArray(data[value])) {
        data[value].forEach((item) => {
          bodyFormData.append(value + "[]", item);
        });
      }else {
        bodyFormData.append(value, data[value] === null ? "" : data[value]);
      }
    });

    return bodyFormData;
  }
};

export default api;
