import React, { Component, Suspense } from 'react'
import { Route, Routes,BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import privateRoute from "./components/PrivateRoute";
import Logout from "./views/pages/login/Logout";
import ToastCenter from "./layout/ToastCenter";
import Loading from "./layout/Loading";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
)

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login         = React.lazy(() => import('./views/pages/login/Login'))
const Page404       = React.lazy(() => import('./views/pages/page404/Page404'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Loading/>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/logout" name="Logout Page" element={<Logout />} />
            <Route exact path="/404" name="Logout Page" element={<Page404 />} />
            <Route path="*" name="Home" element={privateRoute(<DefaultLayout />)}  />
          </Routes>
        </Suspense>
        <ToastCenter/>
      </BrowserRouter>
    )
  }
}

export default App
