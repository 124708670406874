import React from 'react'
import {useSelector} from "react-redux";
import {CToast, CToastBody, CToaster, CToastHeader} from "@coreui/react";

const ToastCenter = () => {
    const app           = useSelector(state => state.app);

    const toast = (title, message, type) => (
        <CToast color={type || "light"} title="Message">
            <CToastHeader close="true">
                <strong className="me-auto">{title || "Message"}</strong>
            </CToastHeader>
            <CToastBody style={{color:type ? "#fff" : "#000"}}>{message}</CToastBody>
        </CToast>
    )

    return (
        <CToaster push={app.success && toast(app.success.title, app.success.message, app.success.toastType)} placement="top-end" />
  )
}

export default ToastCenter
