import axios from "axios";
import store from "src/stores/store"
import {loading, message, setErrors} from "src/stores/app/app.actions";

const apiClient = axios.create({
  baseURL: process.env.BASE_URL || "https://apidev.chicpay.com/api/",
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use((request) => {
  store.dispatch(loading(true));
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    request.headers.Authorization = 'Bearer ' + user.token;
  }
  return request;
});

apiClient.interceptors.response.use((response) => {
  store.dispatch(loading(false));
  if(response.config.method !== "get"){
    store.dispatch(message("Successfully Done.", "Success", "success"));
  }
  return response;
}, (error) => {
  store.dispatch(loading(false));
  if (401 === error.response.status) {
    store.dispatch(message(error.response.data.message || 'Unauthorized. Please login again.','Error', 'danger'));
    window.location.href = '/logout'
  }
  if (404 === error.response.status) {
    store.dispatch(message(error.response.data.message || 'The requested resource Not found','Error', 'danger'));
    // window.location.href = '/404'
  }
  if (403 === error.response.status) {
    store.dispatch(message(error.response.data.message || 'Access denied. Please contact your administrator.', 'Error', 'danger'));
    window.location.href = '/'
  }
  if (500 === error.response.status) {
    store.dispatch(message(error.response.data.message || 'Internal server error. Please contact your administrator.', 'Error', 'danger'));
    // window.location.href = '/500'
  }
  if (422 === error.response.status) {
    // store.dispatch(message('Please check the errors', 'Error', 'danger'));
    store.dispatch(setErrors(error.response.data.errors));
  }
  return Promise.reject(error);
});

export default apiClient;
