import { userConstants } from './auth.constants';

const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.LOGIN:
            return {
                ...state,
                user: action.user,
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                user: null,
            };
        default:
            return state
    }
};

export default auth;
