import {Navigate} from 'react-router-dom';
import store from "src/stores/store"

function PrivateRoute(children) {

    return store.getState().auth.user ? (
        children
    ) : (
        <Navigate to="/login" />
    );
}

export default PrivateRoute;