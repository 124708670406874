import React from 'react'
import {useSelector} from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";


const Loading = () => {
    TopBarProgress.config({
        barColors: {
            "0": "#ffea00",
            "0.5": "#f5c100",
        },
        shadowBlur: 5
    });

    const app = useSelector(state => state.app);
    return app.loading ? (
        <TopBarProgress />
  ) : (<div/>);
}

export default Loading
