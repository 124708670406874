import { appConstants } from './app.constants';

export const message = (message, title, toastType) => {
    return dispatch => {
        dispatch(show(message,title, toastType));
    };
    function show(message, title, toastType) { return { type: appConstants.MESSAGE, message, title, toastType } }
};

export const loading = (status = true) => {
    return dispatch => {
        dispatch(status ? show() : hide());
    };
    function show() { return { type: appConstants.LOADING } }
    function hide() { return { type: appConstants.STOP_LOADING } }
};

export const setErrors = (errors) => {
    return dispatch => {
        dispatch(error(errors));
    };
    function error(errors) { return { type: appConstants.ERROR, errors } }
};