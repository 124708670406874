import { userConstants } from './auth.constants';
import { api } from 'src/services/api/api';

export const login = (username, password) => {
  return dispatch => {
    api.post("auth/login",{username,password})
        .then(response => response.data.data)
        .then(data => {
              const user = { token: data.access_token }
              localStorage.setItem('user', JSON.stringify(user));
              dispatch(success(user));
            });
  };

  function success(user) { return { type: userConstants.LOGIN, user } }
};

export const logout = () => {
  localStorage.removeItem('user');
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT });
    return window.location.href = '/';
  }
}
