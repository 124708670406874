import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk';
import auth from './auth/auth.reducer';
import app from './app/app.reducer';

const initialState = {
  sidebarShow: true,
}

const sideBar = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  sideBar,
  auth,
  app

});

const store = createStore(rootReducer,applyMiddleware(thunkMiddleware))
export default store
