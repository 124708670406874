import { appConstants } from './app.constants';

const initialState = {
    error   : null,
    success : null,
    loading : false,
    errors  : [],
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case appConstants.MESSAGE:
            return {
                ...state,
                success: {
                    message     : action.message,
                    title       : action.title,
                    toastType   : action.toastType,
                },
            };
        case appConstants.LOADING:
            return {
                ...state,
                loading: true
            };

        case appConstants.STOP_LOADING:
            return {
                ...state,
                loading: false
            };

        case appConstants.ERROR:
            return {
                ...state,
                errors: action.errors
            };
        default:
            return state
    }
};

export default app;
